import React from 'react';
import { Button, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link as RouterLink } from 'react-router-dom';
//import './effect.css'

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <div style={contentStyle}>
        <p>© 2024 Minden jog fenntartva. Webshop Partner Team Kft.</p>
        <p>Kapcsolat: logisztika@webshopiroda.hu</p>
        <Link target="_blank" rel="noopener noreferrer" component={RouterLink} to="https://www.facebook.com/webshopirodaugyintezes">
        <Button variant="outlined" startIcon={<FacebookIcon />}>Kövess minket!</Button>
        </Link>
        {/*<a href="" target="_blank">CSOMAGLOGISZTIKA</a>*/}
      </div>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: '#000',  // Fekete háttér
  color: '#666',  // Szürke szöveg
  width: '100%',  // Teljes szélesség
  padding: '20px 0',  // Függőleges padding
  display: 'flex',
  justifyContent: 'center',
};

const contentStyle = {
  maxWidth: '800px',  // Max szélesség
  textAlign: 'center',  // Középre igazított tartalom
  width: '100%',
};






export default Footer;
