import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';

import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

const ASZF = () => {
  const [aszfContent, setAszfContent] = useState('');

  useEffect(() => {
    // ASZF fájl betöltése
    const fetchAszf = async () => {
      try {
        const response = await fetch('/aszf.txt'); // Feltételezve, hogy a public mappában van
        const text = await response.text();
        setAszfContent(text);
      } catch (error) {
        console.error('Hiba az ASZF betöltésekor:', error);
      }
    };

    fetchAszf();
  }, []);

  return (
    <><AppBarComp />
    <Grid container spacing={2} maxWidth={1000} margin="auto" padding={'30px'}>
    <Box p={3} textAlign={'justify'}>
      <div
        dangerouslySetInnerHTML={{ __html: aszfContent }}
      />
    </Box>
    </Grid>
    <Footer />
    </>
  );
};

export default ASZF;
