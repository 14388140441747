import React from 'react';
import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';
import FullWidthIMG from '../components/FullWidthIMG';
import { Grid, Typography } from '@mui/material';
import MyForm from '../components/myform';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



const Ugyfelszolgalat = () => {
  return (
      <>
            <AppBarComp/>
            <FullWidthIMG img_akt={'/ügyfélszolgálat-webáruházaknak-webshopiroda.webp'}/>
            <Grid container spacing={2} maxWidth={1000} margin="auto">
              <Grid>
              <Typography textAlign={'center'}  variant="h5"><i>Az ügyfélszolgálat az értékesítési lánc egyik legfontosabb pillére: ez az a hely, ahol elhagyni kívánt kosarakat fordítunk vissza, ahol upsellek mennek végbe és ahol kedves, segítőkész, élő emberekkel találkozik a vevőd ebben az elidegenedett online világban.</i></Typography>
              <p></p>
              <Typography textAlign={'center'} color='#7cda24' variant="h4"><b>Mi mindentől menetsítünk, ha webshopod logisztikai feladati mellett az ügyfélszolgálatot is ránk bízod?</b></Typography>
              <p></p>
              <Typography padding={'20px'} ><b>A csomaginformációs ügyfélszolgálaton:</b></Typography>
              <Typography textAlign={'justify'}>
                <li>Ha vásárlóid szállítási címet, időpontot szeretnének módosítani, azt közvetlenül nálunk tehetik meg emailen vagy telefonon.</li>
                <li>Segítséget nyújtunk vásárlóidnak, hogy merre jár a csomagjuk, mikor érkezik meg. Hogy érheti el a futárt.</li>
                <li>Ha minimálisan változtatni szeretnének a rendelésük összetételén, közvetlenül minket hívhatnak miatta.</li>
              </Typography>
              <Typography padding= '10px' textAlign={'justify'}>Ez esetben mindig a mi elérhetőségünk van megadva a weboldaladon, így Téged egyáltalán nem zavarnak ezekkel a jellegű kérésekkel.</Typography>
              <Typography padding={'20px'}><b>A titkárság jellegű ügyfélszolgálaton:</b></Typography>
              <Typography>A csomaginformációs (admin jellegű) ügyfélszolgálat tevékenységein túl
                <li>fogadjuk webáruházadban barangoló ügyfeleid hívásait: termék-, készletinformációk nyújtása, felvilágosítás szállításról, ill. egyéb kérdésekben</li></Typography>
              <Typography variant="h7" padding= '10px'>
                <Alert severity="info">
                 <AlertTitle>Tipp:</AlertTitle>
                  <Typography variant="body" padding= '10px' textAlign={'center'}>
                    <i>ezt különösen ajánljuk, ha pl férfiként árulsz nőknek szóló terméket </i>🙂
                  </Typography>
                </Alert>
              </Typography>
              <Typography>
                <li>telefonvonalat biztosítunk, akár zöld vagy kék számot</li>
                <li>részletesebb tájékoztatást nyújtunk vevőidnek egyes termékekkel kapcsolatban</li>
                <li>az információkat, hozzád intézett kérdéseket továbbirányítjuk Hozzád, amennyiben szükséges</li>
                <li>az e-maileket naprakészen kezeljük</li>
                <li>igény szerint nyilvántartásokat vezetünk</li>
                <li>beszállítóiddal időpontot egyeztetünk</li>
                <li>tárgyalót foglalunk a számodra</li>
              </Typography>
              <p></p>
              <Typography padding={'20px'}><b>Sales tevékenységgel kiegészített ügyfélszolgálatunkon</b></Typography>
              <Typography textAlign={'justify'}>Vannak termékek, upsellek, amiket az online értékesítés során nehéz a webes felületen átadni.</Typography>
              <p></p>
              <Typography textAlign={'justify'}>Ilyen esetben az értékesítési lánc fontos eleme a telefonhívás. Ez lehet cross sell jellegű, upsell jellegű termékek ajánlása a vevő felé. Ezt az előre megírt scripted alapján a kéréseidnek és instrukcióidnak megfelelően teljesítünk a rendelőid felé, garantálva ezzel a lehetőségét, hogy a kosárértékek emelkedjenek. </Typography>
              <Typography padding={'20px'}><b>Amiben még szükséged lehet profi háttércsapatunkra:</b></Typography>
              <Typography>
                  <li>rendezvényeiden regisztrációt vállalunk,</li>
                  <li>standszemélyzetet biztosítunk</li>
                  <li>kisebb rendezvényeid szervezésében is segítkezünk</li>
                  <p></p>    
              </Typography>
              <Typography padding={'20px'}><b>Miért ne call centert?</b></Typography>
              <Typography textAlign={"justify"}>Egy call centerrel ellentétben, a Webshop Irodánál ügyfélszolgálatunkon közvetlenül a mi munkatársaink fogadják a hívásokat, akik ismerik a termékeidet, a webshopodat, Téged. Mivel 90%-ban ugyanaz az ember foglalkozik a márkáddal, így ő maximális segítséget tud nyújtani, szinte már együtt lélegzik a cégeddel.</Typography>
              <p></p>
              <Typography textAlign={'justify'}>Az ügyfélszolgálat egy bizalomépítő eszköz, az emberek jó része továbbra is igényli a személyes kontaktust. Mindig lesznek olyan kérdések, melyeket nem tudsz előre megválaszolni a webshopodban, a termékleírásoknál. Ilyenkor az ügyfélszolgálat az egyetlen kiindulási pont.</Typography>
              <p></p>
              <Typography textAlign={'justify'}>A megfelelően képzett, kommunikációs és termékismerettel felruházott ügyfélszolgálatos nagy kincs.</Typography>
              <p></p>
              <Typography textAlign={'justify'}>Egy cégvezető élete nem könnyű: állandó a pörgés, nincs munkaidő, nincs hétvége. Emellett folyton cseng a telefonja:</Typography>
              <p></p>
              <Typography>
                <li>érdeklődés,</li>
                <li>reklamáció,</li>
                <li>megrendelés,</li>
                <li>módosítás,</li>
                <li>lemondás</li>
                <li>esetleg még téves hívások is.</li>
                <p></p>
              </Typography>
              <Typography textAlign={'justify'}>Ezeket a feladatokat nem könnyű mind kézben tartani, erre való az ügyfélszolgálat. Nem beszélve arról, ha rendeléssel kapcsolatos hívás érkezik, akkor azt azonnal le tudjuk kezelni: nincs téves csomagkiküldés azért, mert nem jutott el időben az információ a logisztikához.</Typography>
              <p></p>

              <Typography variant="h7" padding= '10px'>
                <Alert severity="warning">
                 <AlertTitle>Fontos:</AlertTitle>
                  <Typography variant="body" padding= '10px' textAlign={'center'}>
                  Szimpla ügyfélszolgálatot csak ritka esetben vállalunk. A szolgáltatás elsősorban azoknak szól, akik webáruházának logisztikai, csomagmendzsment feladatait a Webshop Iroda látja el.
                  </Typography>
                </Alert>
              </Typography>
              <Typography variant="h5" color='red' textAlign={'center'}>Ha kiszerveznéd ezt is, keress minket bizalommal!</Typography>
              <p></p>
              <Typography textAlign={'center'}>Kapcsolatfelvételhez kérlek használd a lenti űrlapot vagy keress minket telefonon.</Typography>
              <p></p>
              <Typography ><b>Itt tudsz kapcsolatba lépni velünk:</b></Typography>
              <p></p>
              <Typography ><b>telefon:</b> (06 30) 84 83 100</Typography>
              <Typography ><b>fax:</b> (06 27)786 750</Typography>
              <Typography ><b>E-mail:</b> logisztika@webshopiroda.hu</Typography>
              
              <p></p>
              <MyForm />
              <p></p>
              </Grid>
            </Grid>
            <Footer />    
      </>
  );
};

export default Ugyfelszolgalat;