import React from 'react';
import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';
import FullWidthIMG from '../components/FullWidthIMG';
import { Grid, Typography } from '@mui/material';
import PartnerFeedback from '../components/tartalom2';


const Logisztika = () => {

  const partnerWolf = {
    image: `/WolfGabor2017.webp`,
    quote: 'Amikor belefogtam az infomarketingbe és a termékértékesítésbe, az elején szinte belerokkantunk a logisztikába – a garázsunk tele volt dobozokkal, kiszállítási napokon a lakásban álltak a termékek és mi címkéztük őket. Néha persze elkevertük a számlákat – egy káosz volt az egész és egy csomó nyűg! Mióta a WebshopIroda átvette tőlünk a logisztikai feladatokat, azóta az infótermékek eladásával csak két fronton találkozom: kapok egy emailt miden megrendelésről és egy sms-t amikor a vevő fizet. A többit a WebshopIroda intézi profin, megbízhatóan – és én végre koncentrálhatok az üzletfejlesztésre!',
    name: 'Wolf Gábor',
    title: 'Marketing Commando'
  };

  return (
    <>
      <AppBarComp/>
      <FullWidthIMG img_akt={'/webshopiroda-csomaglogisztika-fulfillment-raktározás-csomagolás.webp'}/>
      <Grid container spacing={1} maxWidth={1200} margin="auto" padding={'10px'}>
        <Grid>
        <Typography padding= '40px' textAlign={'center'} color='#7cda24' variant="h6"><b>„Siker dobozolva”- azaz infótermékek és webáruházak logisztikai és pénzügyi feladatainak komplett kiszervezése, hogy Neked csak a pénzt kelljen számolni!</b></Typography>
        <Typography textAlign={'justify'}>Minden interneten értékesítő üzletnek van egy része, amiről senki sem szeret beszélni: a termékek logisztikája (gyártás raktározás, leltárnyilvántartás, szállítás, stb) és ezekhez kapcsolódó pénzügyek (megrendelések regisztrációja, számlák kiállítása, számlamódosítási kérések ügyintézése, esetleg garanciák miatti sztornózás, stb)</Typography>
        <p></p>
        <Typography textAlign={'justify'}>OHHH, HA EZT TUDTAM VOLNA, SOSE KEZDEK BELE! – gondolják sokan, mert rájönnek, hogy a passzívnak hitt online kereskedelem közel sem jelent passzív jövedelmet.</Typography>
        <p></p>
        <Typography textAlign={'justify'}>Hacsak nem a termékek beszerzése után már nem Te, hanem mi viseljük a termék minden gondját, baját, amíg a vevő átveszi!</Typography>
        <p></p>
        <Typography padding= '40px' textAlign={'center'} color='#7cda24' variant="h6"><b>15 nagy lépés, amit meg kell lépni minden interneten értékesítőnek és ami el kell döntenie, hogy saját maga teszi meg, vagy pedig ránk bízza:</b></Typography>
        <p></p>
        <Typography padding= '0px' textAlign={'justify'}>
      <ol>
        <li>A raktárba érkezett termékeidet nyilvántartásba vesszük, a ki és beszállításokat folyamatosan nyomonkövetjük. Így a készlet állapotáról bármikor napra készen tudunk tájékoztatni. Sőt, időben figyelmeztetünk, hogy a raktárkészletet látva az átlagos eladások mellett mikor jött el az ideje gondoskodni a termék utángyártásáról, rendeléséről.</li>
        <p></p>
        <li>Az általad előírt kritériumok szerint minden darabot ellenőrzünk, kiszűrjük a hibás termékeket.</li>
        <p></p>
        <li>A termékeidre érkező megrendeléseket folyamatosan regisztráljuk. Ezeket több csatornán keresztül is tudjuk fogadni. A lehetőségek felmérése után közösen választjuk ki azt, amellyel maximális nyugalomban egyet tudsz érteni velünk.</li>
        <p></p>
        <li>A megrendeléseket szüksége esetén visszaigazoljuk. Illetve felvesszük a kapcsolatot a vevővel, pl adategyeztetés, vagy egyéni igények megválaszolása céljából.</li>
        <p></p>
        <li>Szükség esetén elkészítjük a kísérőleveleket, amelyeket a termékek mellé kívánsz csatolni. Akár a vevőnek névre szabottan.</li>
        <p></p>
        <li>Ha nincs automata számlázás a rendszeredben, akkor a mindenkori törvényeknek megfelelően elkészítjük mi a számlát a vevőd felé.</li>
        <p></p>
        <li>Összeállítjuk a csomagokat: ilyenkor a termék igényeihez igazodva a legoptimálisabb csomagolóanyagot használjuk, mely minden esetben  környezetkímélő anyagokból áll.</li>
        <p></p>
        <li>Megszervezzük a csomagok kiszállítását (postai úton vagy futár céggel). Amennyiben a vevőd személyesen kívánja átvenni a terméket, munkaidőben erre is lehetőséget biztosítunk: saját raktárunk árukiadójában, csomagpontukon illetve a GLS csomagpontokon is van erre lehetőség.</li>
        <p></p>
        <li>A vevőket email-ben és sms-ben is kiértesítjük a küldemény státuszáról és várható érkezéséről. </li>
        <p></p>
        <li>Ha a marketing gépezetednek eleme, hogy utánkövető elemek küldesz a vevőidnek, erre is van lehetőség. Postai úton vagy futárral is el tudjuk küldeni neki az ajánlatot. Illetve telefonos utánkövetéssel is tudjuk támogatni az értékesítő rendszeredet.</li>
        <p></p>
        <li>Rutinosan járunk el nagyker-kiskeres, elosztóközpontos (pl könyváruházas) beszállítások esetén is.</li>
        <p></p>
        <li>Az át nem vett termékek, visszáruk, a mi raktárunkba kerülnek vissza. Ott ellenőrizzük az épségüket, és ennek megfelelően vételezzük őket vissza polcra vagy selejtre, melyről írásban értesítünk Téged. </li>
        <p></p>
        <li>A visszaérkezett csomagok számláját sztornózzuk, szükség esetén helyesbítő számlát állítunk ki és azt postázzuk a nem-vevőnek.</li>
        <p></p>
        <li>A számlákat továbbítjuk a könyvelésed felé. Illetve igény esetén vállaljuk céged teljes könyvelését is.</li>
        <p></p>
        <li>Az elszámolások havonta, indokolt esetben kéthetente történnek – a csomagforgalom alapján. Először egy előzetes elszámolólapot küldünk a számodra, majd ennek maradéktalan jóváhagyása után a számlát. </li>
      </ol>
      </Typography>
<Typography padding= '20px'><b>Kinek ajánljuk szolgáltatásainkat?</b></Typography>
<Typography padding= '0px' textAlign={'justify'}>
<li>Azoknak akik szeretnék, ha az infótermékek menedzsmentjének adminisztratív és logisztikai feladatait levegye a vállukról egy ebben tapasztalt szakértő.</li>
<p></p>
<li>Azoknak, akik kis stábbal szeretnének dolgozni, és nem akarnak külön belső alkalmazottat fizetni az internetes üzletáguk menedzsmentjéhez.</li>
<p></p>
<li>Azoknak, akik értékelik a korrekt elszámolást a szolgáltatás díja ugyanis attól függ, hogy mekkora volumenű a forgalmad.</li>
</Typography>
<p></p>
<Typography padding= '20px'><b>Mennyibe kerül?</b></Typography>
<Typography>Minden megbízáshoz külön árajánlatot készítünk, mely az alábbi konstrukció szerint épül fel:</Typography>
<Typography padding= '0px' textAlign={'justify'}>
  <li>Raktározási költség: minden hónapban pontosan annyi, amennyi helyet igényel a termékeid tárolása. </li>
  <p></p>
  <li>Logisztikai díj per csomag árasan: a logisztikai díj minden általunk kezelt csomag után „per darab” fizetendő, melyre sávos árkedvezményt biztosítunk. </li>
  <p></p>
  <li>Ügyfélszolgálat admin, kihelyezett és sales opciókkal:</li>
  <p></p> 
  <li>Lehetőséged van csak admin ügyfélszolgálatot igénybe venni, mely szigorúan a csomaginformációkkal kapcsolatban ad segítséget ügyfeleidnek.</li>
  <p></p>
  <li>A kihelyezett ügyfélszolgálat a teljes telefonons és emailes megkereséseid kiszolgálását jelenti. </li>
  <p></p>
  <li>A sales ügyfélszolgálat már az értékesítési rendszeredet támogatja. </li>
</Typography>
  <Typography padding= '20px' textAlign={'justify'}><b>Hogyan tovább?</b></Typography>
<Typography padding= '0px' textAlign={'justify'}>
<ol>
  <li>Vedd fel velünk a kapcsolatot emailen vagy telefonon >> logisztika@webshopiroda.hu , +36303474531</li>
  <p></p>
  <li>Minden esetben szeretünk megismerkedni az ügyfeleinkkel, így ezután visszahívunk és igyekszünk megtudni minél többet a termékedről, az igényeidről, hogy minél személyreszabottabb ajánlatot tudjunk küldeni számodra. Illetve megválaszolunk minden benned felmerülő kérdést velünk és a szolgáltatásunkkal kapcsolatban.</li>
  <p></p>
  <li>Emailen elküldjük a személyre szabott ajánlatot számodra.</li>
</ol>
</Typography>
<p></p>
      </Grid>
      </Grid>
      <PartnerFeedback feed={  partnerWolf  }/>
      <Footer />    
    </>
  );
};

export default Logisztika;