import { Grid, Typography, Card, CardMedia, CardContent, } from '@mui/material';
//import Divider from '@mui/material/Divider';

const staffData = [
  { image: '/Dublinszki-L%C3%A1szl%C3%B3-Webshopiroda.webp', name: 'Dublinszki László', title: 'Alapító, tulajdonos', comment: 'Akihez bármikor fordulhatsz kéréseiddel, kérdéseiddel.' },
  { image: '/11.webp', name: 'Havan Lilla', title: 'Marketing és operatív menedzser', comment: 'Aki a háttérben mozgatja szálakat: web, marketing, rendszerek.' },
  { image: '/women.webp', name: 'Lamár Vivien', title: 'Irodai munkatárs', comment: '' },
  { image: '/women.webp', name: 'Szurcsik Andrea', title: 'Irodai munkatárs', comment: '' },
  { image: '/men.webp', name: 'Csendes Zsolt', title: ' Irodai munkatárs', comment: '' },
  { image: '/women.webp', name: 'Kozóné Szabó Zsuzsanna', title: 'Logisztikai munkatárs', comment: '' },
  { image: '/men.webp', name: 'Aczél Zoltán', title: '', comment: '' },
  { image: '/men.webp', name: 'Pénzes Attila', title: '', comment: '' },
  { image: '/men_x.webp', name: 'Akár TE is lehetsz', title: 'Logisztikai munkatársunk', comment: '<a href="/raktaros">Nézd meg nyitott pozicióinkat...</a>' }
];

const OurTeam = () =>{

  return (
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'0px'}>
      {staffData.map((staff, index) => (
         <>
              <Grid item xs={12} sm={4} key={index}>
            <Card sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start',
                height: '100%' // A kártyák magassága kitölti a rendelkezésre álló helyet
              }}>
            <CardMedia component="img" image={staff.image} alt={staff.name} />
            <CardContent>
            <Typography variant="body"><b>{staff.name}</b></Typography>
            <Typography variant="body2" textAlign={'justify'}>{staff.title}</Typography>
            <p></p><Typography variant="body2" color='darkgray' textAlign={'justify'}><div dangerouslySetInnerHTML={{ __html: staff.comment }}></div></Typography>
            </CardContent>
        </Card>
      </Grid>
      </>
      ))}
    </Grid>
  );
}

export default OurTeam;