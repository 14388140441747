import { Box } from '@mui/material';
import { Link } from 'react-router-dom';


const Hero = () =>{
  return (
<><Box
      component="img"
      src="/hero.webp"
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    <div style={{textAlign: 'center', padding: '40px'}}>
    <Link  to="/kapcsolat" sx={{ textDecoration: 'none' }}><button
          style={{
            padding: '10px 20px',
            fontSize: '1.2rem',
            color: '#007BFF',
            backgroundColor: '#FFF',
            border: '2',
            lineHeight: '25px',
            borderColor: '#007BFF',
            borderRadius: '5px',
            cursor: 'pointer',
          }}

        >
          BŐVEBB INFÓT SZERETNÉK...
        </button></Link >
        </div>
        </>
  );
}

export default Hero;