import React from 'react';
import { Grid, Button, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import MyForm from './myform';
import { Link as RouterLink } from 'react-router-dom';

const ContactForm = () => {
    return (
    <Grid container spacing={2} maxWidth={1000} margin="auto" padding={'30px'}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body2" align="center" padding={'10px'}>
          <p></p>Hívásod mindig örömmel fogadjuk!<p></p><b>+36308483100</b><p></p>Vagy írj nekünk, ami számodra kényelmesebb:<p><b>logisztika@webshopiroda.hu</b></p>
        </Typography>
        <Typography align="center">
        <Link target="_blank" rel="noopener noreferrer" component={RouterLink} to="https://www.facebook.com/webshopirodaugyintezes">
        <Button variant="outlined" startIcon={<FacebookIcon />}>Kövess minket!</Button>
        </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <MyForm/>
        </Grid>
    </Grid>
  );
};

export default ContactForm;
