import { Grid, Card, CardMedia, CardContent, CardActions, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Content3 = () => {
  const cards = [
    { image: '/csomaglogisztika-webshopiroda-fulfillment-1.webp', title: 'CSOMAGLOGISZTIKA', description: 'Heti pár csomagtól napi több tucat kezeléséig, fix havidíj nélkül.Raktározzuk a terméked, kezeljük a rendeléseidetbecsomagoljuk: számlával, kísérőlevéllel ellátva kifutározzuk a vevődnek kezeljük az át nem vett csomagokat Te már csak a befolyt pénzzel találkozol. 🙂', szolgurl: '/logisztika' },
    { image: '/csomaglogisztika-webshopiroda-%C3%BCgyf%C3%A9lszolg%C3%A1lat.webp', title: 'ÜGYFÉLSZOLGÁLAT', description: 'Azonnali segítségnyújtás vásárlóidnak e-mailen és telefonon Kihelyezheted hozzánk emailes és telefonos ügyfélszolgálatodat, hogy a vásárlóid ne a Te napodat szakítsák meg a kérdéseikkel. Akár csomagcsere miatt, akár termékkel kapcsolatos információkért hívnak a vásárlóid, mi mindig készséggel és kedvesen állunk a rendelkezésére, hiszen tudjuk, hogy ez az értékesítés egyik legfontosabb állomása.', szolgurl: '/ugyfelszolgalat' },
    { image: '/csomaglogisztika-webshopiroda-k%C3%B6nyvel%C3%A9s-sz%C3%A1ml%C3%A1z%C3%A1s.webp', title: 'KÖNYVELÉS', description: 'Teljes céges könyvelés, vagy csak futárelszámolás Ha a könyvelőd járatlan benne vagy pontatlanul végzi a futárszolgálat elszámoló íveinek könyvelését, bízd ránk nyugodtan, mi évek óta csináljuk. De ha jobb szeretnéd egy helyen tudni a céged összes adminisztrációs-pénzügyi feladatát, nálunk van lehetőséged rá.', szolgurl: '/konyveles' }
  ];

  return (
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'30px'}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <Card sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start',
                height: '100%' // A kártyák magassága kitölti a rendelkezésre álló helyet
              }}>
            <CardMedia component="img" image={card.image} alt={card.title} />
            <CardContent>
              <Typography variant="h6">{card.title}</Typography>
              <Typography variant="body2" textAlign={'justify'}>{card.description}</Typography>
            </CardContent>
            <CardActions sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-end',
                alignItems: 'start',
                height: '100%' // A kártyák magassága kitölti a rendelkezésre álló helyet
              }}>
               <Link  to= {card.szolgurl} sx={{ textDecoration: 'none' }}><Button size="small">Bővebben...</Button></Link>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Content3;