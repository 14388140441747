import React from 'react';
import { Grid, Typography } from '@mui/material';

import AppBarComp from '../components/AppBar'
import FullWidthIMG from '../components/FullWidthIMG';
import Footer from '../components/footer';
import DolgozzKep from './DolgozzKep';

const Dolgozz = () => {
  return (
    <>
      <AppBarComp/>
      <FullWidthIMG img_akt={'/dolgozz.webp'}/>
            <Grid container spacing={2} maxWidth={800} margin="auto">
              <Grid>
                <Typography textAlign={'justify'} variant="body">Ha érdekel az online kereskedelem back-office része, amiről senki nem beszél – nálunk van lehetőséged belekóstolni.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body" > A webáruházak csomaglogisztikája az a híd, amin keresztül eljut a termék az online térben lévő eladótól az offline világban élő vevőig.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body"> Ha érdekel Téged ez a terület, és szeretnél a része lenni annak a gépezetnek, ami az online értékesítés utolsó állomásán történik, ne habozz jelentkezni hozzánk.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Az állásinterjún a következő fog történni veled</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Megismertetünk a cégünk Vezető gondolataival, hogy tudd, mi az ami számunkra fontos. Mi a missziónk, amiért dolgozunk.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Itt el tudod dönteni, hogy tudsz-e azonosulni ezzel, mert nekünk fontos, hogy magadénak tudd érezni a céget, hiszen mi egy CSAPAT vagyunk. Ugyanazokért a célokért dolgozunk, együtt, egységesen, különbségek nélkül. Hiszen ugyanolyan fontos a stratégia és az is, hogy tisztaság legyen a környezetünkben. Nem de?</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Elmeséljük neked milyen elvek mentén dolgozunk, mert hiszünk benne, hogy minden leírt szónak ereje van. Nem szeretnénk, ha a munkatársakban kérdések maradnának – nálunk mindenre van válasz.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Ezután megkérünk, hogy mesélj magadról. Minket nem a rakat iskolád érdekel, hanem, hogy TE magad milyen vagy. Mesélj nekünk a családodról, a hobbidról, az értékelveidről. Hogy mi a célod? Mit szeretnél elérni a következő 1-3-10 évben. Hogy mi a legnagyobb félelmed, mert mindenkinek van ilyen. 🙂 </Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Hogy mi volt eddig a legnagyobb élményed, mi volt a legviccesebb dolog, ami történt veled.  És természetesen ezt megtesszük mi is, mert egész biztosan tudni szeretnéd kikkel fogod tölteni ezentúl a napod nagy részét.</Typography>
                <p></p>
                <Typography textAlign={'center'} variant="h5" >Ha itt megvan kettőnk között a szimpátia, és maximálisan biztos vagy benne, hogy szeretnél csatlakozni hozzánk, akkor...</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body" >Annak a gépezetnek, ami az online értékesítés utolsó állomásán történik, ne habozz jelentkezni hozzánk.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Megmutatjuk mi hol van, hogyan működik a kávéfőző, hol találod az ellátmányokat (mert van ám sokminden 🙂 , hol tudod eltölteni a pihenőidődet, hol lesz a te munkaállomásod. Átadjuk a munkádhoz szükséges eszközöket.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body">Itt csatlakozik hozzánk a mentorod is, aki az elejében erősebben fogja fogni a kezed, majd egyre önálóbb munkára sarkall, hisz’ nálunk felnőttek dolgoznak, akik idővel megpróbálnak maguk megbirkózni mindennel. Ne félj, nem hagyunk magadra, ebben segítségedre lesznek az elvek és természetesen bármikor kérdezhetsz.</Typography>
                <p></p>
                <Typography textAlign={'justify'} variant="body"> Végigvesszük a pontos munkaköri leírásodat, megbeszéljük a bérezésedet és hogy miként lehet nálunk pluszokat kapni, majd belevágunk az első napodba. 🙂 </Typography>
                <p></p>
                <Typography textAlign={'left'} variant="h5" >Munkatársak, akiket várunk:</Typography>
                <p></p>
                <DolgozzKep Mtitle={''} mimg={'/RAKTÁROS-munkatárs-webshopiroda-csomaglogisztikánál.webp'} murl={'/raktaros'}/>
                <p></p>
                <DolgozzKep Mtitle={''} mimg={'/csomagoló-munkatárs-webshopiroda-csomaglogisztika.webp'} murl={'/csomagolo'}/>
                <p></p>
                <DolgozzKep Mtitle={''} mimg={'/Könyvelő-webshopiroda-csomaglogisztika.webp'} murl={'/konyvelo'}/>
                <p></p>


              </Grid>
            </Grid>
      <Footer />    
    </>
  );
};

export default Dolgozz;