import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Button, InputBase, Drawer, List, ListItem, ListItemText, Box, useScrollTrigger, Slide, Link, Fab } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Search, Menu as MenuIcon, ArrowDropDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
//import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LOGO_URL from '../assets/wsi-logo.jpg';

// HideOnScroll komponens a fejléc scrollozásra történő elrejtéséhez
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menu);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const handleSearchToggle = () => {
    setSearchOpen((prev) => !prev);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const renderMenuButtons = () => (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      {/* Menü gombok */}
      <Button
        aria-controls="services-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'services')}
        sx={{ color: 'brown' }}
        endIcon={<ArrowDropDown />}
      >
        Szolgáltatások
      </Button>
      <Menu
        id="services-menu"
        anchorEl={anchorEl}
        open={activeMenu === 'services'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/ugyfelszolgalat" sx={{ color: 'brown', textDecoration: 'none' }}>Ügyfélszolgálat</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/konyveles" sx={{ color: 'brown', textDecoration: 'none' }}>Könyvelés</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/logisztika" sx={{ color: 'brown', textDecoration: 'none' }}>Logisztika</Link></MenuItem>
      </Menu>

      <Button
        aria-controls="references-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'references')}
        sx={{ color: 'brown' }}
      >
        <Link component={RouterLink} to="/referenciak" sx={{ color: 'brown', textDecoration: 'none' }}>Referenciák</Link>
      </Button>

      <Button
        aria-controls="contact-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'contact')}
        sx={{ color: 'brown' }}
        endIcon={<ArrowDropDown />}
      >
       Kapcsolat
      </Button>
      <Menu
        id="contact-menu"
        anchorEl={anchorEl}
        open={activeMenu === 'contact'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/kapcsolat" sx={{ color: 'brown', textDecoration: 'none' }}>Kapcsolat</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/GDPR" sx={{ color: 'brown', textDecoration: 'none' }}>Adatvédelmi nyilatkozat</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/ASZF" sx={{ color: 'brown', textDecoration: 'none' }}>ÁSZF</Link></MenuItem>
      </Menu>

      <Button
        aria-controls="work-menu"
        aria-haspopup="true"
        onClick={(e) => handleMenuClick(e, 'work')}
        sx={{ color: 'brown' }}
        endIcon={<ArrowDropDown />}
      >
        Dolgozz velünk
      </Button>
      <Menu
        id="work-menu"
        anchorEl={anchorEl}
        open={activeMenu === 'work'}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/raktaros" sx={{ color: 'brown', textDecoration: 'none' }}>Raktáros</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/csomagolo" sx={{ color: 'brown', textDecoration: 'none' }}>Csomagoló</Link></MenuItem>
        <MenuItem onClick={handleMenuClose}><Link component={RouterLink} to="/konyvelo" sx={{ color: 'brown', textDecoration: 'none' }}>Könyvelő</Link></MenuItem>
      </Menu>
     
    
    </Box>
  );

  return (
    <>
      <HideOnScroll>
        <AppBar position="sticky" sx={{ backgroundColor: 'white', color: 'brown' }}>
          <Toolbar>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {/* Logo balra igazítva */}
              <Box sx={{ flexGrow: 1 }}>
                <Link component={RouterLink} to="/">
                  <img src={LOGO_URL} alt="Logo" style={{ height: '80px', width: 'auto' }} />
                </Link>
              </Box>

              {/* Mobil nézet: hamburger menü */}
              {isMobile ? (
                <>
                  <IconButton onClick={toggleDrawer(true)} sx={{ color: 'brown' }}>
                    <MenuIcon />
                  </IconButton>
                  <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                    <Box sx={{ width: 200 }} onClick={toggleDrawer(false)}>
                      <List>
                      <ListItem button>
                      <ListItemText sx={{ color: 'grey', textDecoration: 'none' }} primary="Szolgáltatások" />
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/ugyfelszolgalat" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Ügyfélszolgálat" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/konyveles" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Könyvelés" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/logisztika" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Logisztika" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/referenciak" sx={{ color: 'grey', textDecoration: 'none' }}><ListItemText primary="Referenciák" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/kapcsolat" sx={{ color: 'grey', textDecoration: 'none' }}><ListItemText primary="Kapcsolat" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/GDPR" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Adatvédelmi nyilatkozat" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/ASZF" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- ÁSZF" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/karrier" sx={{ color: 'grey', textDecoration: 'none' }}><ListItemText primary="Dolgozz velünk" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/raktaros" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Raktáros" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/csomagolo" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Csomagoló" /></Link>
                    </ListItem>
                    <ListItem button>
                    <Link component={RouterLink} to="/konyvelo" sx={{ color: 'brown', textDecoration: 'none' }}><ListItemText secondary="- Könyvelő" /></Link>
                    </ListItem>
                      </List>
                    </Box>
                  </Drawer>
                </>
              ) : (
                renderMenuButtons()
              )}

              {/* Kereső ikon */}
              <IconButton onClick={handleSearchToggle} sx={{ color: 'brown' }}>
                <Search />
              </IconButton>

              {/* Kereső mező */}
              {searchOpen && (
                <InputBase
                  placeholder="Keresés..."
                  sx={{ ml: 1, flexGrow: 1, color: 'black', border: '1px solid brown', padding: '2px 4px' }}
                />
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      {/* Visszagörgető nyíl */}
      {showScrollButton && (
        <Fab
          color="#61c2b8"
          size="large"
          onClick={scrollToTop}
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </>
  );
}
