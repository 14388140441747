import React from 'react';
import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const DolgozzKep = ( {mtitle, mimg, murl} ) => {
    return (
       <>
       <Typography textAlign={'center'}>{mtitle}</Typography>
       <Typography textAlign={'center'}><Box
      component="img"
      src= { mimg }//'/megrendelt-termekek-7.webp'
      sx={{
        width: '60%',
        height: 'auto',
        objectFit: 'cover'
      }}>
       </Box> 
       <p></p>
       <Button variant="outlined" color="inherit" component={Link} to={ murl } >Érdekel</Button>
       </Typography>
       </>
    );
};

export default DolgozzKep;