import React, { useState } from 'react';
import { Grid, Box, TextField, Button, Typography, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
//import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from 'react-router-dom';

const MyForm = () => {
  // State-ek a form mezőinek kezelésére és visszajelzés megjelenítésére
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [privacyAccepted, setPrivacyAccepted] = useState(false); // Adatvédelmi irányelvek elfogadása

  // Formadatok kezelése
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Checkbox állapot kezelése
  const handleCheckboxChange = (e) => {
    setPrivacyAccepted(e.target.checked);
  };

  // Submit esemény kezelése
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');

    try {
      //const response = await fetch('http://api.magnew.hu:5052/send', {
        const response = await fetch('https://dlomail.hu/send_message.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setResponseMessage('Üzenet elküldve sikeresen!');
      } else {
        setResponseMessage(`Hiba történt: ${result.error || 'Ismeretlen hiba'}`);
      }
    } catch (error) {
      setResponseMessage(`Hálózati hiba: ${error.message}`);
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={0} maxWidth={800} margin="auto" padding={'10px'}>
      
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">Üzenj nekünk!</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto' }}>
          <TextField
            fullWidth
            label="Név"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            margin="normal"
            size="small"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            margin="normal"
            size="small"
          />
          <TextField
            fullWidth
            label="Üzenet"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            multiline
            rows={4}
            margin="normal"
            size="small"
          />

<FormControlLabel
            control={
              <Checkbox
                checked={privacyAccepted}
                onChange={handleCheckboxChange}
                name="privacy"
              />
            }
            label= "Elfogadom az adatvédelmi irányelveket."
          />
          {<Link  to= "/GDPR" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>(GDPR)</Link>}
                  
          {loading ? (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={!privacyAccepted} // Inaktív, amíg nincs elfogadva a checkbox
            >
              Küldés
            </Button>
          )}

          {responseMessage && (
            <Typography variant="body2" align="center" color="primary" marginTop={2}>
              {responseMessage}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyForm;
