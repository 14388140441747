import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import AppBarComp from '../components/AppBar';
//import ContactForm from '../components/tartalom7';
import MyForm from '../components/myform';
import Footer from '../components/footer';
import GoogleMapEmbed from '../components/Map';

const Contacts = () => {
  return (
    <>
      <AppBarComp/>
      <Grid container spacing={0} maxWidth={1200} margin="auto" padding={'30px'}>
      <Grid item xs={12} sm={12} textAlign="center"><h1>Mindig örömmel segítünk. 🙂</h1></Grid>
      <Grid item xs={12} sm={12} textAlign="left" >
      <Typography variant="h6" component="div" gutterBottom><b>Webshop Partner Team Kft.</b></Typography></Grid>
      <p></p>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} sm={3} > <Box>
      <Typography variant="body1" component="div" gutterBottom>
        <b>Székhely:</b>
        <Typography>2133 Sződliget,</Typography>
        <Typography> Petőfi Sándor u. 9.</Typography>
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <b>Raktár:</b>
        <Typography>2600 Vác, Zrínyi u. 41/b.</Typography>
      </Typography>
      <p></p>
      <Typography variant="body1" component="div" gutterBottom>
        <b>Cégadatok:</b>
        <Typography variant="body1" component="div" gutterBottom  padding={'10px'}>
        <b>Cégjegyzékszám:</b>
        <Typography>13-09-235973</Typography>
        <b>Adószám:</b>
        <Typography>32638563-2-13</Typography>
        <b>Bankszámlaszám:</b>
      
      <Typography>64700124-16877012</Typography>
      (MBH Bank Nyrt.)
      </Typography>
      </Typography>
    </Box>
</Grid>
    <Grid item xs={12} sm={3} >
      
    <Typography variant="body1" component="div" gutterBottom>
        <b>Telefonszámaink:</b>
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <Typography>0630/84-83-100,</Typography>
        <Typography>0630/34-74-531</Typography>
      </Typography>
      <Typography variant="body1" component="div" gutterBottom>
        <b>E-mail:</b> logisztika@webshopiroda.hu
      </Typography>
    </Grid>
    <Grid item xs={12} sm={5} ><MyForm /></Grid>
    </Grid>
    </Grid>
    <GoogleMapEmbed />
    <Footer />  

    
    </>
  )
};

export default Contacts;
