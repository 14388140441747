import { Box } from '@mui/material';



const FullWidthImage2 = ( {file} ) =>{
  return (
    <><Box
      component="img"
      src= { file }//'/megrendelt-termekek-7.webp'
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    
    </>
  );
}

export default FullWidthImage2;