import { Box } from '@mui/material';

const FullWidthIMG = ( {img_akt} ) =>{
    console.log( img_akt );
  return (
    <><Box
      component="img"
      src= { img_akt }
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    </>
  );
}

export default FullWidthIMG;