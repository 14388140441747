import Counter from '../components/counter';
import { Typography, Grid, Divider } from '@mui/material';

function Counters( {textTrue} ) {
  return (
    <>
    <div style={containerStyle}>
      <Grid container spacing={2} maxWidth={1000} margin="auto">
          { textTrue && (
            <><Typography variant="h5" padding={'20px'}>Nem, nem ma kezdtük…</Typography>
            <Typography textAlign={'justify'} padding={'15px'}>
          Vállalkozásunk 2010-ben indult, eleinte Laci egyedül intézett mindent logisztika fronton, Zsuzsa és Julcsi pedig a pénzügyi dolgokért felelt. 2011-ben csatlakozott a céghez Andi, majd a többiek. És aki jó feleségként a mai napig is csak a háttérben marad, Lilla. 🙂
          Ahogy az ügyfélkör és az igények nőttek, úgy fejlődtünk mi is. Ma épp itt tartunk és ugyanolyan szívvel és lelkesedéssel csináltuk, mint sok-sok évvel ezelőtt az első napon. 🙂<p></p>
          </Typography>
          </>
        )}
        <Grid container spacing={2} maxWidth={1000} margin="auto" >
          <Grid item xs={12} sm={4} >
            <div style={counterStyle}>
              <Counter value={238} title="ügyfélszolgálati email" title3="és telefonhívás naponta" title2="db" duration={1.5} />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} >
            <div style={counterStyle}>
              <Counter value={670} title="raktár különböző termékigényekhez" title2="nm" duration={2} />
          </div>
          </Grid>
          <Grid item xs={12} sm={4} >
            <div style={counterStyle}>
              <Counter value={827652} title="feladott csomag" title2="db" duration={3} />
            </div>
          </Grid>
        </Grid>    
      </Grid>  
    </div>
    <Divider/>
    </>
  );
}

const containerStyle = {
    display: 'flex',           // Flexbox layout
    justifyContent: 'space-around', // Egyenletesen elosztva
    maxWidth: '1000px',         // Maximális szélesség 800px
    margin: '0 auto',          // Középre igazítva a képernyőn
    padding: '20px 0',         // Függőleges padding
    gap: '20px',               // Rés a div-ek között
  };
  
  const counterStyle = {
    flex: '1',                 // Flexibilis szélesség, hogy egymás mellé kerüljenek
    textAlign: 'center',       // Középre igazítva a szöveg
  };

export default Counters;