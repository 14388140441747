import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import AppBar from './AppBar';
import Home from './pages/Home';
import Ugyfelszolgalat from './pages/Ugyfelszolgalat';
import Konyveles from './pages/Konyveles';
import Logisztika from './pages/Logisztika';

import Referenciak from './pages/Referenciak';
import Kapcsolat from './pages/Kapcsolat';
import ASZF from './pages/aszf';
import Adatvedelmi from './pages/adatvedelem';
import Munkatars from './pages/munkatars';
import Dolgozz from './pages/Dolgozz';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  typography: {
    fontSize: 17, // Alapértelmezett betűméret
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '1.2rem', // Body betűméretének beállítása
          lineheight: '1.5em',
        },
      },
    },
  },
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/ugyfelszolgalat" element={<Ugyfelszolgalat />} />
        <Route path="/konyveles" element={<Konyveles />} />
        <Route path="/logisztika" element={<Logisztika />} />
        <Route path="/referenciak" element={<Referenciak />} />

        <Route path="/kapcsolat" element={<Kapcsolat />} />
        <Route path="/ASZF" element={<ASZF />} />
        <Route path="/GDPR" element={<Adatvedelmi />} />
        <Route path="/karrier" element={<Dolgozz />} />
        <Route path="/raktaros" element={<Munkatars file={'/raktaros.txt'} />} />
        <Route path="/csomagolo" element={<Munkatars file={'/csomagolo.txt'} />} />
        <Route path="/konyvelo" element={<Munkatars file={'/konyvelo.txt'} />} />
        {/*}<Route path="/karrier" element={<Karrier />} />*/}
        Raktaros
      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;

