import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';

import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

const Munkatars = ({ file }) => {
  const [MunkatarsContent, setMunkatarsContent] = useState('');

  useEffect(() => {
    // ASZF fájl betöltése
    const fetchMunkatars = async () => {
      try {
        const response = await fetch(file); // Feltételezve, hogy a public mappában van
        const text = await response.text();
        setMunkatarsContent(text);
      } catch (error) {
        console.error('Hiba az ASZF betöltésekor:', error);
      }
    };

    fetchMunkatars();
  }, [file]);

  return (
    <>
    <AppBarComp />
    <Grid container spacing={2} maxWidth={1000} margin="auto" padding={'30px'}>
    <Box p={3} textAlign={'justify'}>
      <div
        dangerouslySetInnerHTML={{ __html: MunkatarsContent }}
      />
    </Box>
    </Grid>
    <Footer />
    </>
  );
};

export default Munkatars;
