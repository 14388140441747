import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';
import PartnerFeedback from '../components/tartalom2';
import { styled } from '@mui/material/styles';
import Partners from '../components/tartalom6';
import Counters from '../components/tartalom9';
import ContactForm from '../components/tartalom7';

const partnerWolf = {
    image: `/WolfGabor2017.webp`,
    quote: 'Amikor belefogtam az infomarketingbe és a termékértékesítésbe, az elején szinte belerokkantunk a logisztikába – a garázsunk tele volt dobozokkal, kiszállítási napokon a lakásban álltak a termékek és mi címkéztük őket. Néha persze elkevertük a számlákat – egy káosz volt az egész és egy csomó nyűg! Mióta a WebshopIroda átvette tőlünk a logisztikai feladatokat, azóta az infótermékek eladásával csak két fronton találkozom: kapok egy emailt miden megrendelésről és egy sms-t amikor a vevő fizet. A többit a WebshopIroda intézi profin, megbízhatóan – és én végre koncentrálhatok az üzletfejlesztésre!',
    name: 'Wolf Gábor',
    title: 'Marketing Commando'
  };
  
  const partnerJanka = {
    image: `/CSerhát-HErold-Janka.webp`,
    quote: 'Nagy lépés volt az életünkben, mikor kiszerveztük a logisztikát. Már a  webshop indulása előtt elhatároztam, hogy amint lehet, ezt megteszem. Emlékszem, hogy ennek ellenére mennyire féltem, hogy mi lesz, ha ezután nem én intézek majd mindent. Ma már tudom, hogy a félelmem alaptalan volt, és nagyon örülök, hogy Laciékkal vágtunk bele a változásba. Rugalmasak, valódi támogatást nyújtanak, és azóta több idő jut a fejlődésre és növekedésre. Igazi win-win helyzet. Ajánlom, hogy ne hezitálj, ha a csomagok már kinőtték a gyerekszobát vagy a garázst, hanem tedd meg magadnak azt a szívességet, hogy kiszervezed ezeket a feladatokat a Webshop irodának. 🙂',
    name: 'Cserháti-Herold Janka',
    title: 'termékenységi specialista, Hormonmentes.hu'
  };
  
  const partnerCsaba = {
    image: `/Brasó-Csaba.webp`,
    quote: 'Meggyőződésem, hogy legértékesebb valutánk a bizalom. Azért ilyen értékes, mert nehéz olyan partnert találni, akiben teljes mértékben megbízhatunk, akár emberileg, akár üzletileg. Azonban az elmúlt egy év alatt, amióta a Webshop Irodával dolgozom rendszeresen érnek pozitív meglepetések ezen a téren. Megdöbbentő felfedezés volt, hogy egyszerűen nemcsak, hogy rájuk lehet bízni az ügyfeleimet, hanem sokkal jobb döntés rájuk bízni, mert széleskörű tapasztalataik segítségével a lehető legmagasabb szintű kiszolgálásban részesítik őket. Mindezen felül pedig még az összes adminisztrációs terhet is leveszik a vállamról, pontos és megbízható elszámolásaikkal nagymértékben könnyebbé teszik az életemet. Elmondhatatlanul hálás vagyok azért a nagyszerű hozáállásukért is, hogy amikor elrontok valamit, akkor ők maguktól ajánlják fel a segítségüket – és teljesen megbízhatok abban, hogy meg is oldják amit elrontottam. Örülök, hogy velük dolgozhatom!',
    name: 'Braskó Csaba',
    title: 'A Múzsa című önfejlesztő könyv szerzője, a Színház- és Filmművészeti egyetem tanára, Szemléletfejlesztés.hu'
  };
  
  const partnerGyorgy = {
    image: `/bodnar-gyorgy.webp`,
    quote: 'Nem nekünk kell a legjobb futárszolgálatot megkeresni, nem nekünk kell egyeztetni, hogy mikor és hol vehetik át tőlünk az árut, nem nekünk kell odafigyelni az utánvétes és előre fizető vevők elkülönítésére, nem nekünk kell számlázni. És ami a legfontosabb: nélkülünk is olajozottan zajlik a vevők kiszolgálása! Már ezért is érdemes akár egy könyvsorozat írójának is (én ilyen vagyok) profi logisztikai céget választani. A Webshop Iroda esetében mindehhez még rugalmasság és családias kapcsolat, személyesség is társul!',
    name: 'Bodnár György',
    title: 'író, Az “Építem a házam” könyvsorozat szerzője'
  };

  /*const style = {
    py: 0,
    width: '100%',
    maxWidth: 360,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  };

  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));*/

const Referenciak = () => {
  return (
    
    <>
      <AppBarComp/>
      <Grid container spacing={2} maxWidth={1000} margin="auto">
        <Typography padding= '100px' textAlign={'center'} color='#7cda24' variant="h5"><b>Ők már velünk dolgoznak – reméljük Te leszel a következő. 🙂</b></Typography>
        <p></p>
        <PartnerFeedback feed={ partnerWolf }/>
        <Partners />
        <div>
        {/*<Divider sx={{ color: '#1AB519' }} textAlign="center">Ügyfeleink</Divider>*/}
        </div>
        <Grid container spacing={2} maxWidth={800} margin="auto" padding={'0px'}>
          <Grid item xs={12} sm={6} ><PartnerFeedback feed={ partnerCsaba }/>
            </Grid>
            <Grid item xs={12} sm={6} ><PartnerFeedback feed={ partnerGyorgy }/>
            </Grid>
            </Grid>
            <Typography padding= '100px' textAlign={'center'}  variant="h5"><b>Nyugodtan bízz bennünk – ahogy a számlálókon is látod, nem ma kezdtük. 🙂</b></Typography>
      <Counters />
      <PartnerFeedback feed={ partnerJanka }/>
      <ContactForm />
    </Grid>
    <Footer /> 
    </>
    );
};

export default Referenciak;