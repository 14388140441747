import { Box, Grid, Typography } from '@mui/material';
import { BsChatQuote } from "react-icons/bs";

const PartnerFeedback = ( { feed } ) => {
  return (
    <Box sx={{ bgcolor: '#fff4cc', py: 4 }}>
      <Grid container spacing={2} maxWidth={1000} margin="auto">
        <Grid padding={'10px'} item xs={8} sm={8} md={3}>
          <Box component="img" src={feed.image} alt={feed.name} sx={{ borderRadius: '450px', width: '100%', height: 'auto' }} />
        </Grid>
        <Grid padding={'40px'} item xs={12} sm={12} md={9}>
        <BsChatQuote color='brown' size={30}/>
          <Typography textAlign={'justify'} variant="body2">{feed.quote}</Typography>
          <p></p>
          <Typography variant="h8"><b>{feed.name}</b></Typography>
          
          <Typography variant="subtitle2">{feed.title}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartnerFeedback;