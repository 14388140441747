import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';

import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

const Adatvedelmi = () => {
  const [aszfContent, setAdatvContent] = useState('');

  useEffect(() => {
    // ASZF fájl betöltése
    const fetchAdatv = async () => {
      try {
        const response = await fetch('/adatvedelmi_iranyelvek.txt'); // Feltételezve, hogy a public mappában van
        const text = await response.text();
        setAdatvContent(text);
      } catch (error) {
        console.error('Hiba a dokumentum betöltésekor:', error);
      }
    };

    fetchAdatv();
  }, []);

  return (
    <><AppBarComp />
    <Grid container spacing={2} maxWidth={1000} margin="auto" padding={'30px'}>
    <Box p={3} textAlign={'justify'}>
      <div
        dangerouslySetInnerHTML={{ __html: aszfContent }}
      />
    </Box>
    </Grid>
    <Footer />
    </>
  );
};

export default Adatvedelmi;
