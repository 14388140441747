import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CounterComponent = ({ value, title, title2, title3, duration }) => {
  const [count, setCount] = useState(0);
  const [isPulsing, setIsPulsing] = useState(false); // Pulzáló állapot

  useEffect(() => {
    let start = 0;
    const totalDuration = duration * 1000; // Az időtartamot milliszekundumra váltjuk
    const increment = value / (totalDuration / 50);  // Lépésköz kiszámítása 50 ms-ra
    const stepTime = 50;  // 50 ms lépésidő

        // Pulzáló animáció indítása az időtartam utolsó 1 másodpercében
        /*const pulseTimeout = setTimeout(() => {
            setIsPulsing(true); // Pulzálás indítása
          }, totalDuration - 1000);*/

    const counterInterval = setInterval(() => {
      start += increment;
      if (start >= value) {
        setCount(value);  // Beállítjuk a végső értéket
        clearInterval(counterInterval);  // Megállítjuk az időzítőt
      } else {
        setCount(Math.ceil(start));  // Kerekítsük a számot felfelé
      }
    }, stepTime);

    return () => clearInterval(counterInterval); // Tisztítás az unmount esetén
  }, [value, duration]);

  return (
    <div style={containerStyle}>
      <h1 style={numberStyle}>{count} {title2}</h1>  {/* Kék színű, h1 méretű szám */}
      <Typography maxWidth={'80%'} style={{ ...titleStyle, ...(isPulsing ? pulsingStyle : {}) }}>{title} </Typography><Typography> {title3}</Typography>  {/* 'body' méretű szöveg, pulzálás */}
      </div>
      
  );
};

// Stílusok
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const numberStyle = {
  color: '#61c2b8',  // Kék szín
  fontSize: '2rem',  // h1 méret
  margin: '0',
};

const titleStyle = {
  fontSize: '1.2rem',  // 'body' méret
  color: '#333',
};

const pulsingStyle = {
    animation: 'pulse 1s infinite',  // Pulzáló animáció 1 mp-ig
    transform: 'scale(1.5)',  // 20%-kal nagyobb lesz az eredeti méretnél
  };

export default CounterComponent;
