import { Grid, Typography } from '@mui/material';
import AppBarComp from '../components/AppBar'
import Footer from '../components/footer';
import FullWidthIMG from '../components/FullWidthIMG';
import PartnerFeedback from '../components/tartalom2';

const Konyveles = () => {
  const partnerCsaba = {
    image: `/Brasó-Csaba.webp`,
    quote: 'Meggyőződésem, hogy legértékesebb valutánk a bizalom. Azért ilyen értékes, mert nehéz olyan partnert találni, akiben teljes mértékben megbízhatunk, akár emberileg, akár üzletileg. Azonban az elmúlt egy év alatt, amióta a Webshop Irodával dolgozom rendszeresen érnek pozitív meglepetések ezen a téren. Megdöbbentő felfedezés volt, hogy egyszerűen nemcsak, hogy rájuk lehet bízni az ügyfeleimet, hanem sokkal jobb döntés rájuk bízni, mert széleskörű tapasztalataik segítségével a lehető legmagasabb szintű kiszolgálásban részesítik őket. Mindezen felül pedig még az összes adminisztrációs terhet is leveszik a vállamról, pontos és megbízható elszámolásaikkal nagymértékben könnyebbé teszik az életemet. Elmondhatatlanul hálás vagyok azért a nagyszerű hozáállásukért is, hogy amikor elrontok valamit, akkor ők maguktól ajánlják fel a segítségüket – és teljesen megbízhatok abban, hogy meg is oldják amit elrontottam. Örülök, hogy velük dolgozhatom!',
    name: 'Braskó Csaba',
    title: 'A Múzsa című önfejlesztő könyv szerzője, a Színház- és Filmművészeti egyetem tanára, Szemléletfejlesztés.hu'
  };

  return (
    <>
      <AppBarComp/>
      <FullWidthIMG img_akt={'/webshop-könyvelés-logisztika-fulfillment-webshopiroda.webp'}/>
      <Grid container spacing={1} maxWidth={1000} margin="auto">
        <Grid><Typography padding= '10px' textAlign={'center'} color='#7cda24' variant="h6"><b>BÁR AZ EMBER SZERETNÉ AZT HINNI, HOGY „AZ ÉN CÉGEM – AZ ÉN PÉNZEM” SZABÁLY ÉRVÉNYESÜL A MINDENNAPOKBAN, EZ SAJNOS NEM ÍGY VAN…</b></Typography>
        <p></p>
        <Typography padding= '10px' textAlign={'justify'}>Abban a pillanatban, ha egy cég megalakul, az erre szánt pénzt egy olyan zsebbe raktad, amiből nem költhetsz szabadon bármire.</Typography>
        <Typography padding= '10px' textAlign={'justify'}>Sőt, többféle adó is terheli majd vállalkozásodat – hogy csak a legfontosabbakat említsük: társasági és helyi iparűzési adó, illetve a vállalkozásban foglalkoztatottak után fizetendő közterheket. Minimálisan is, de sajnos kénytelen vagy megismerkedni az alapszabályokkal, hogy megfelelően tudj árrést számolni, tisztában legyél a nullpont számítással, mérlegelni tudj üzleti lehetőségk közül, hogy az megéri vagy nem éri meg.</Typography>
        <Typography padding= '10px' textAlign={'justify'}>Egy jó könyvelő nem csupán a számlák iktatásával és a bevallások elkészítésvel foglalkozik. Ha a jövőbeni terveidről is beszél velünk, segíteni tudunk megtervezni a legoptimálisabb módot arra is, hogy ne érjenek váratlan meglepetések és a lehető legoptimálisabban hozd ki az új lehetőségekből azt, ami bennük rejlik.</Typography>
        <Typography padding= '10px' textAlign={'left'} color='#7cda24' variant="h6"><b>HA</b></Typography>
        <Typography padding= '10px'>
        <li>Most indítottad vagy most tervezed indítani a vállalkozásodat és nincs még könyvelőd?</li>
        <li>Elégedetlen vagy a jelenlegi könyvelőddel</li>
        <li>Unod, hogy jelenlegi könyvelőd számára a Te céged is csak egy a sok közül és a nevedet is csak a cégpapírokból tudják?</li>
        <li>Nem kapsz elég figyelmet? Szemmel láthatóan türelmetlenül várja, hogy befejezd a kérdéseidet?</li>
        <li>Túl későn kapsz tájékoztatást a céged helyzetéről?</li>
        <li>Nem kapsz friss információkat a Rád vonatkozó és valóban érintett adójogi változásokról?</li>
        <li>Nincs időd leadni az anyagot nyitvatartási időben?</li>
        </Typography>
      <Typography padding= '10px' textAlign={'left'} color='#7cda24' variant="h6"><b>NE HABOZZ ÉS KERESS MINKET BIZALOMMAL EGY BESZÉLGETÉS EREJÉIG, HOGY LÁSD MILYENEK VAGYUNK. 🙂</b></Typography>
      <Typography padding= '10px' >Munkatársaink regisztrált mérlegképes könyvelő képesítéssel és jelentős szakmai tapasztalattal rendelkeznek – valamennyien rugalmas, elhivatott és diszkrét segítői lehetnek vállalkozásodnak.</Typography>
      <p></p>
      <Typography padding= '10px' >A könyvelés és tanácsadás, céges ügyintézés kulturált, családias környezetben zajlik, előzetes egyeztetés szerint rugalmas munkaidőben várunk irodánkba.</Typography>
      <PartnerFeedback feed={ partnerCsaba }/>
      </Grid>
      </Grid>
      <Footer />    
    </>
  );
};

export default Konyveles;