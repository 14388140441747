import { Grid, Box } from '@mui/material';
//import Divider from '@mui/material/Divider';


const partnerLogos = [
  '/3-1.webp',
  'https://sparitual.com/cdn/shop/t/25/assets/sparitual-logo.svg',
  '/4.webp',

];
const Partners = () =>{

  return (
    <>
<p></p>
    
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'30px'}>
      {partnerLogos.map((logo, index) => (
        <Grid item xs={6} sm={12} key={index}>
          <Box component="img" src={logo} alt={`Partner ${index}`} sx={{ width: '100%' }} />
        </Grid>
      ))}
    </Grid>
     
      </>
  );
}

export default Partners;