import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './styles.css'; 

const Content1 = () => {

//const MyComponent = () => {
    useEffect(() => {
      const handleScroll = () => {
        const elements = document.querySelectorAll('.fade-in-on-scroll');
        elements.forEach((element) => {
          const rect = element.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom >= 0) {
            element.classList.add('visible');
          } else {
            element.classList.remove('visible');
          }
        });
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


  return (
    <Box
      sx={{
        maxWidth: 1800,
        margin: 'auto',
        backgroundImage: 'url(/megrendelt-termékek-5.webp)',
        backgroundSize: 'cover',
        p: 2,
      }}
    > 
    <Grid container spacing={2} maxWidth={800} margin="auto" padding={'30px'}>
      <Typography variant="body1" textAlign={'justify'}>Eleinte teljesen természetes, hogy az weben értékesítők életének a része a megrendelések feldolgozása, a számla készítés, az ügyfelekkel való csevegés. Euforikus állapotba kerülsz, amikor látod, hogy napról napra több rendelésed érkezik.</Typography>
      <p></p>
      <Typography variant="body1"><b>Aztán eljön a nap………</b></Typography>
      <p></p>
      <Typography variant="body1" textAlign={'justify'}>
Eljön a nap, amikor jó lenne végre valami mást, valami hasznosat csinálni:
<li>alakítgatni a hirdetéseken,</li>
<li>csiszolni a weboldal szövegén,</li>
<li>csinálni 1-2 live videót,</li>
<li>előkészíteni egy kampányt,</li>
<li>megírni pár emailt és kiküldeni a listára.</li>
<li>Uram bocsá, egy egész napra eltűnni</li>
<li>elmenni egy tréningre, konferenciára vagy csak úgy kivenni egy napot és semmit sem csinálni. </li>
<p></p>
<Typography textAlign={'justify'}>
Pffúúúúúúúúúj, MICSODA BŰNÖS GONDOLATOK!!!!
Ehelyett most épp azon kapod magad, hogy már nem fejlődő vállalkozás vagy, hanem egy rabszolga a saját cégedben, amit anno azért hoztál létre, hogy szabad legyél. </Typography>
</Typography><p></p>
<Typography textAlign={'justify'}><b>JÓ LENNE VÉGRE IDŐT ÉS PÉNZT TAKARÍTANI MEG, AMIT A WEBÁRUHÁZAD FORGALMÁNAK NÖVELÉSÉRE ÉS MAREKTINGRE TUDNÁL FORDÍTANI?</b></Typography>
<p></p>
<Typography textAlign={'center'}>
<Typography variant="h6" className="fade-in-on-scroll">Elhoztunk egy időt és energiát megtakarító szolgáltatást Nektek,
webshop tulajdonosoknak és online értékesítőknek:</Typography>
<Typography variant="h6" className="fade-in-on-scroll"><b>KÖTÖTTSÉGMENTES FULLFILMENT</b></Typography>
</Typography><p></p>
  <Typography>
      <li>Ha már napi 4-5 megrendelésed van,</li>
      <li>Ha túl sok idődet veszi el a számlázás és a csomagfeladás,</li>
      <li>Ha hidegrázásod van az ügyféltelefonoktól, email áradattól,</li>
      <li>Ha csak kiegészítésként üzemeltetsz webáruházat, és alig van időd rá napközben</li>
      <li>Ha csak egy terméked van vagy írtál egy könyvet,</li>
      <li>Ha az ajánlataidat elegánsan szeretnéd eljuttatni az érdeklődőidnek</li>
      <li>Ha nincs helyed raktározni az árudat, termékedet</li>
      <li>Ha maróan frusztrál, hogy külön szervezést igényel, ha el akarsz menni egy tréningre, konferenciára,</li>
      <li>Ha fojtogat a gondolat, hogy sosem mehetsz el szabadságra, és a futárhoz kell igazítanod a napodat, akkor</li>
  </Typography>
<p></p>
<Typography variant="h6" textAlign={'center'}><b>ITT AZ IDEJE KISZERVEZNED A TERMÉKEID LOGISZTIKAI FELADATAIT!</b></Typography>
</Grid>
 </Box>
    
  );
}

export  default Content1;