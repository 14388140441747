import React from 'react';
import { Box } from '@mui/material';

const GoogleMapEmbed = () => {
  return (
    <Box display="flex" justifyContent="center" marginTop={3}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d920.8141293987616!2d19.123399585926773!3d47.78586323331652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47402b420507eb09%3A0xe80ec1cbe4d0625!2sWebshopiroda.hu!5e0!3m2!1sen!2shu!4v1727563073902!5m2!1sen!2shu"
        width="1200"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </Box>
  );
};

export default GoogleMapEmbed;
