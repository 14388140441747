import { Box } from '@mui/material';



const FullWidthImage = () =>{
  return (
    <><Box
      component="img"
      src="/sz%C3%A1mla-2F-sz%C3%A1ll%C3%ADt%C3%B3-beleheyez%C3%A9se.webp"
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover'
      }}
    />
    <Box
    component="img"
    src="/Kik-vagyunk-mi_.webp"
    sx={{
      width: '100%',
      height: 'auto',
      objectFit: 'cover'
    }}
  />
    </>
  );
}

export default FullWidthImage;